import ErrorPage from "../components/errorPage";

export type NotFoundProps = {
  preview: boolean;
};

export function NotFound(props: NotFoundProps) {
  const primaryLineOne = "Oops, something";
  const primaryLineTwo = "went wrong.";
  const primaryText = (
    <>
      <span>{primaryLineOne}</span>
      <br />
      <span>{primaryLineTwo}</span>
    </>
  );
  const secondaryText =
    "We're sorry, but it looks like the page that you're looking for can't be found.";

  return (
    <ErrorPage
      primaryText={primaryText}
      secondaryText={secondaryText}
      errorCode="404"
    />
  );
}

export default NotFound;
