import { ReactNode } from "react";

export type ErrorPageProps = {
  errorCode: "404" | "500";
  primaryText: ReactNode;
  secondaryText: string;
};

export function ErrorPage(props: ErrorPageProps) {
  return (
    <div className="flex flex-col items-center">
      <h1 className="type-head-2 sm:type-head-1 md:type-broadcast-1 bg-gradient-to-b from-teal to-green bg-clip-text pb-[1rem] font-rebrand text-[70px] font-bold leading-[75px] text-colorless md:text-[130px] md:leading-[130px]">
        <em>{props.primaryText}</em>
      </h1>
      <p className="type-body-2 mt-8">
        <strong>Error {props.errorCode}</strong>
      </p>
      <p className="type-body-2">{props.secondaryText}</p>
    </div>
  );
}

export default ErrorPage;
